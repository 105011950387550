import Link from "next/link";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { GetServerSideProps } from "next";
import { useSession, getSession, signIn } from "next-auth/client";

import Layout from "../layouts/onboarding";
import usePageEvents from "../hooks/use-page-events";
import SpinningLoader from "../components/SpinningLoader";
import { EventType, CategoryType } from "../context/analytics-context";
import HeadMeta from "../components/HeadMeta";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default function Home() {
  const router = useRouter();
  const [session, loading] = useSession();
  const sendAnalytics = usePageEvents("index");
  const [signingIn, setSigningIn] = useState(false);

  const { utm_id, callbackUrl } = router.query;

  useEffect(() => {
    if (utm_id) {
      setSigningIn(true);

      sleep(250).then(() => {
        signIn("credentials", {
          phone: utm_id,
          redirect: false
        }).then(() => {
          sendAnalytics({
            category: CategoryType.AUTH,
            type: EventType.AUTO_SIGNIN,
            page: "index"
          });

          getSession().then(session => {
            if (session) {
              window.location.href = "/discover";
              // router.replace("/discover");
            } else {
              setSigningIn(false);
            }
          });
        });
      });
    }
  }, [utm_id]);

  useEffect(() => {
    if (callbackUrl) {
      sendAnalytics({
        category: CategoryType.NAVIGATION,
        type: EventType.REDIRECT,
        page: "index",
        path: callbackUrl
      });

      window.location.href = callbackUrl as string;
    }
  }, [callbackUrl]);

  useEffect(() => {
    if (session) {
      sendAnalytics({
        category: CategoryType.NAVIGATION,
        type: EventType.REDIRECT,
        page: "index",
        path: "/discover"
      });

      window.location.href = "/discover";
    }
  }, [session]);

  const navEvent = () => {
    sendAnalytics({
      category: CategoryType.NAVIGATION,
      type: EventType.URL_CLICK,
      page: "index",
      path: "/signin"
    });

    router.replace("/signin");
  };

  return (
    <div className="flex flex-col h-full">
      <HeadMeta title="Welcome" url="/" />

      <div className="flex flex-col items-center justify-center flex-shrink-0 h-2/5">
        <div className="w-24 sm:w-28">
          <img src="/images/white-logo.svg" alt="" className="w-full" />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center flex-1 px-8 text-center">
        <p className="px-3 text-xl font-semibold leading-6 uppercase">
          Discover, Listen, read & watch your Favorite Gospel Tunes
        </p>
        <p className="py-6 mt-4 text-base">Welcome to Angaza Music</p>

        {loading || signingIn ? (
          <div className="mt-6 mb-10">
            <SpinningLoader />
          </div>
        ) : (
          <Link href="/signin">
            <a
              onClick={navEvent}
              className="text-sm px-8 py-3 sm:py-3.5 mt-4 signin-btn highlight-none"
            >
              Sign In
            </a>
          </Link>
        )}
      </div>
    </div>
  );
}

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>;
};

export const getServerSideProps: GetServerSideProps = async context => {
  const session = await getSession(context);

  if (session) {
    return {
      redirect: {
        destination: "/discover",
        permanent: false
      }
    };
  }

  return {
    props: { session }
  };
};

import React from "react";
import Head from "next/head";
import { APP_DESCRIPTION, APP_IMAGE, APP_NAME, APP_URL } from "../lib/constants";

type HeadMetaProps = {
  title: string;
  url: string;
  description?: string;
  image?: string;
  og_type?: "website" | "article" | "video";
  twitter_card?: "summary_large_image" | "summary" | "player";
};

const HeadMeta: React.FC<HeadMetaProps> = ({
  title,
  url,
  description = APP_DESCRIPTION,
  image = APP_IMAGE,
  og_type = "website",
  twitter_card = "summary_large_image"
}) => (
  <Head>
    <title>{APP_NAME || title}</title>
    <meta name="description" content={description} />

    <meta name="twitter:url" content={`${APP_URL}${url}`} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:card" content={twitter_card} />

    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={`${APP_URL}${url}`} />
    <meta property="og:image" content={image} />
    <meta property="og:type" content={og_type} />

    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
  </Head>
);

export default HeadMeta;

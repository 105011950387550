import Head from "next/head";

export default function Layout(props) {
  return (
    <>
      <Head>
        <link rel="stylesheet" href="/css/onboarding.css" />
      </Head>

      <div
        className="flex flex-col flex-1 object-cover w-full h-full max-w-xl mx-auto bg-gray-100 bg-top bg-no-repeat bg-cover"
        style={{ backgroundImage: "url(/images/bg-onboarding-full.png)" }}
      >
        {props.children}
      </div>
    </>
  );
}

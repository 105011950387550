import { useEffect, useRef, useState } from "react";
import { CategoryType, EventType, useAnalytics } from "../context/analytics-context";

import { isBrowser } from "../lib/utils";

const debounce = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export default function usePageEvents(
  page: string,
  pageLoad = true,
  contentId?: string
): (event: AnalyticsEvent) => void {
  const events = useRef([]);
  const { sendAnalytics } = useAnalytics();
  const [startTime, setStartTime] = useState(new Date().getTime() / 1000);

  const captureEvent = (name: string) => {
    events.current.push({
      event: name,
      timestamp: Math.floor(new Date().getTime() / 1000)
    });
  };

  useEffect(() => {
    if (pageLoad) {
      sendAnalytics({
        category: CategoryType.PAGE,
        type: EventType.PAGELOAD,
        page,
        contentId
      });
    }

    setStartTime(new Date().getTime() / 1000);

    if (isBrowser) {
      window.onclick = debounce(() => captureEvent("click"));
      window.onkeypress = debounce(() => captureEvent("keypress"));
      window.ontouchstart = debounce(() => captureEvent("touchstart"));
      window.onmousemove = debounce(() => captureEvent("mousemove"));
      window.onmousedown = debounce(() => captureEvent("mousedown"));
      window.addEventListener(
        "scroll",
        debounce(() => captureEvent("scoll")),
        true
      );
    }

    return () => {
      const timeOnPage = new Date().getTime() / 1000 - startTime;
      sendAnalytics({
        category: CategoryType.PAGE,
        type: EventType.PAGEEXIT,
        events: events.current,
        timeOnPage,
        page,
        contentId
      });

      window.removeEventListener(
        "scroll",
        debounce(() => captureEvent("scoll")),
        true
      );
    };
  }, []);

  return sendAnalytics;
}
